import React, { useRef, useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import '../assets/login.css'
import JsonData from "../data/data.json";
import qs from "../utils/qs";
import { useCookies } from "react-cookie";
import axios from "axios";

export const Login = () => {
  const navigate = useNavigate();

  const ref = useRef(null);

  // const [inputPw, setInputPw] = useState('');
	// // input data 의 변화가 있을 때마다 value 값을 변경해서 useState 해준다
  // const handleInputId = (e) => {
  //   setInputId(e.target.value)
  // }

  // const handleInputPw = (e) => {
  //   setInputPw(e.target.value)
  // } 

  console.log('렌더링 됨');

  // 페이지 렌더링 후 가장 처음 호출되는 함수
  useEffect(() => {
    console.log('컴포넌트가 화면에 나타남');
    // return () => {
    //   console.log('컴포넌트가 화면에서 사라짐');
    // };
    // fetch(JsonData.SERVER_URL + "api/token", {t
    //   headers: {

    //   }
    // })
    // .then(response => response.json())
    // .then(result => {
    //   console.log(result);
    // });
  }, []);


//const [name,setName] = useState("");
//       //렌더링 될 때 마다 실행
// 	useEffect(()=>{
//     console.log('렌더링 때마다 실행');
//   });
  
//   //처음 렌더링 되거나 배열 값이 바뀔때마다 실행
// useEffect(()=>{
//     console.log('name 변수가 바뀔 때마다 실행');
//   },[name]);
  
//   //처음 렌더링이 될 때만 실행
// useEffect(()=>{
//     console.log('처음 렌더링 때만 실행');
//   },[]);

  /*
  * 로그인 전송
  */
  const loginFunc = (event) => {
    event.preventDefault();
    console.log("Login", event.currentTarget);
    const data = new FormData(event.currentTarget);

    if (data.get("email") == '') {
      ref.current.email.focus();
      return;
    } else if(data.get("password") == '') {
      ref.current.password.focus();
      return;
    } 

    // var formBody = [];
    // formBody.push(encodeURIComponent("email") + '=' + encodeURIComponent(data.get("email")));
    // formBody.push(encodeURIComponent("password") + '=' + encodeURIComponent(data.get("password")));
    // formBody = formBody.join('&');

    const infos = {
      email: data.get("email"),
      password: data.get("password"),
    }

    axios.post('/token', qs(infos)).then(response => {
      const { accessToken } = response.data;

      // API 요청하는 콜마다 헤더에 accessToken 담아 보내도록 설정
      axios.defaults.headers.common['Authorization'] = `${accessToken}`;

    }).catch(error => {

    });

    // fetch(JsonData.SERVER_URL + "api/token", {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded'
    //   },
    //   body: formBody
    // })
    // .then(response => response.json())
    // .then(result => {
    //   if(result.result == 200) {
    //     console.log('success', result);
    //     // localStorage.setItem("accessToken", result.accessToken);
    //     // localStorage.setItem("refreshToken", result.refreshToken);
    //     navigate("/");
    //   } else {
    //     ref.current.password.value = '';
    //     alert("id password invalid");
    //   }
    // });
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={loginFunc} ref={ref}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Login</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              name="email"
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              name="password"
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Forgot <Link to='/findpassword'>password?</Link>
          </p>
        </div>
      </form>
    </div>
  );
};
import React, { useState, useContext, lazy, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//PAGE
import { Home } from "./components/homeJsx/home";
import { Login } from "./components/login";
import { Join } from "./components/join";
import { FindPassword } from "./components/findPassword";
import JsonData from "./data/data.json";

import './App.css';

//#lifeCycle
//getDerivedStateFromProps()
//shouldComponentUpdate()
//render()
//getSnapshotBeforeUpdate()
//componentDidUpdate()

class App extends React.Component {
  constructor(props) {
    super(props)

    this.state = { landingPageData: JsonData }
  }

  // brefore render
  // static getDerivedStateFromProps(props, state) {
  //   return {brand: props.color };
  // }

  // shouldComponentUpdate() {
  //   return true;
  // }

  // componentDidMount() {
  // }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   document.getElementById("div1").innerHTML =
  //   "1 Before the update, the favorite was " + prevState.brand;
  // }

  // componentDidUpdate() {
  //   document.getElementById("div2").innerHTML =
  //   "2 The updated favorite is " + this.state.brand;
  // }

  // changeModel = (a) => {
  //   this.setState({brand: a});
  // }

  // deleteChildView = () => {
  //   this.setState({show: false});
  // } 

  render() {
    // const [landingPageData, setLandingPageData] = useState({});
    // useEffect(() => {
    //   setLandingPageData(JsonData);
    // }, []);

    return (
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home/>} />

          <Route path='/login' element={<Login/>} />

          <Route path='/join' element={<Join/>} />

          <Route path='/findpassword' element={<FindPassword/>} />
      
        </Routes>
      </BrowserRouter>

        
        // {/* <Navigation />
        // <Header data={this.state.landingPageData.Header} />
        // <Features data={this.state.landingPageData.Features} />
        // <About data={this.state.landingPageData.About} />
        // <Services data={this.state.landingPageData.Services} />
        // <Gallery data={this.state.landingPageData.Gallery} />
        // <Testimonials data={this.state.landingPageData.Testimonials} />
        // <Team data={this.state.landingPageData.Team} />
        // <Contact data={this.state.landingPageData.Contact} /> */}
  
     
            //   <div>
            //   <h2>Hi, I am a {this.state.brand} Car!</h2>
            //   <button type="button" onClick={ () => this.changeModel("good")}>Change color</button>
            //   <button type="button" onClick={this.deleteChildView}>delete child view</button>
            //   <div id="div1"></div>
            //   <div id="div2"></div>
            // </div>
    );
  }
}

export default App;

import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import '../assets/login.css'


export const Join = (props) => {
  const [inputId, setInputId] = useState('');
  const [inputPw, setInputPw] = useState('');
  const navigate = useNavigate();

	// input data 의 변화가 있을 때마다 value 값을 변경해서 useState 해준다
  const handleInputId = (e) => {
    setInputId(e.target.value)
  }

  const handleInputPw = (e) => {
    setInputPw(e.target.value)
  } 

  // 페이지 렌더링 후 가장 처음 호출되는 함수
  useEffect(() => {
    console.log('컴포넌트가 화면에 나타남');
    return () => {
      console.log('컴포넌트가 화면에서 사라짐');
    };
  }, [])

  const loginFunc = (e) => {
    e.preventDefault();
    console.log("Login Test");
    
    //navigate("다음페이지로 가는 router URL", {state : {값이름: 값}}); 
    navigate("/");
  }

  return (
    <div className="Auth-form-container">
      <form className="Auth-form" onSubmit={loginFunc}>
        <div className="Auth-form-content">
          <h3 className="Auth-form-title">Join</h3>
          <div className="form-group mt-3">
            <label>Email address</label>
            <input
              type="email"
              className="form-control mt-1"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group mt-3">
            <label>Password</label>
            <input
              type="password"
              className="form-control mt-1"
              placeholder="Enter password"
            />
          </div>
          <div className="d-grid gap-2 mt-3">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="forgot-password text-right mt-2">
            Forgot <a href="#">password?</a>
          </p>
        </div>
      </form>
    </div>
  );
};
import React, { useState } from 'react';
import { Link } from "react-router-dom";

import { Navigation } from "./navigation";
import { Header } from "./header";
import { Features } from "./features";
import { About } from "./about";
import { Services } from "./services";
import { Gallery } from "./gallery";
import { Testimonials } from "./testimonials";
import { Team } from "./Team";
import { Contact } from "./contact";
import SmoothScroll from "smooth-scroll";
import JsonData from "../../data/data.json";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
  });

export const Home = (props) => {
    const [state, setState]  = useState(JsonData);

    return (
        <div>
            <Navigation />       
            <Header data={state.Header} />
            <Features data={state.Features} />
            <About data={state.About} />
            <Services data={state.Services} />
            <Gallery data={state.Gallery} />
            <Testimonials data={state.Testimonials} />
            <Team data={state.Team} />
            <Contact data={state.Contact} />
        </div>
    );
};